<template>
  <div>
    <div class="container-fluid">
      <div class="row row-height">
        <div class="col-xl-4 col-lg-4 content-left">
          <div class="content-left-wrapper">
            <a
              id="logo"
              href="/"
            ><img
              src="/img/logo.png"
              alt="logo"
              width="150"
            ></a>
            <div id="social">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/Peeringhub-102756598508748"
                    target="_blank"
                  ><i class="icon-facebook" /></a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/LoganDa11020"
                    target="_blank"
                  ><i class="icon-twitter" /></a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/peeringhub"
                    target="_blank"
                  ><i class="icon-linkedin" /></a>
                </li>
              </ul>
            </div>
            <!-- /social -->
            <div>
              <figure>
                <img
                  src="/img/info_graphic_1.svg"
                  alt=""
                  class="img-fluid"
                  width="270"
                  height="270"
                >
              </figure>
            </div>
          </div>
          <!-- /content-left-wrapper -->
        </div>
        <!-- /content-left -->
        <div
          id="start"
          class="col-xl-8 col-lg-8 content-right"
        >
          <div id="wizard_container">
            <div id="top-wizard">
              <span
                v-if="currentStep > 0"

                id="location"
              >{{ currentStep }}/{{ steps }} completed</span>
              <div id="progressbar">
                <div
                  class="step"
                  :style="{ width: `${progressBarWidth}%` }"
                />
              </div>
            </div>
            <!-- /top-wizard -->
            <form
              id="wrapped"
              method="post"
              enctype="multipart/form-data"
            >
              <input
                id="website"
                name="website"
                type="text"
                value=""
              >
              <!-- Leave for security protection, read docs for details -->
              <div id="middle-wizard">
                <!-- /Start Branch ============================== -->
                <div
                  v-if="pageStep == 'first'"
                  class="step"
                  data-state="branchtype"
                >
                  <h2 class="section_title">
                    Eligibility
                  </h2>
                  <h3 class="main_question">
                    Are you already a STIR/SHAKEN Service Provider?
                  </h3>
                  <div class="form-group input">
                    <span
                      v-if="transition && !available"
                      class="error"
                    >Required</span>
                    <label
                      v-for="item in status_items"
                      :key="item.id"
                      class="container_radio version_2"
                    >{{ item.text }}
                      <input
                        v-model="status"
                        type="radio"
                        name="availability"
                        class="required"
                        :value="item.value"
                      >
                      <span class="checkmark" />
                    </label>
                  </div>
                </div>
                <div
                  v-if="pageStep == 'about_company'"
                  class="step"
                >
                  <h2 class="section_title">
                    Tell Us More About Your Company
                  </h2>
                  <!-- <h3 class="main_question">
                                      Please tell us about your company
                                    </h3> -->
                  <div
                    v-for="item in step1_items"
                    :key="item.label"
                    class="form-group input"
                  >
                    <span
                      v-if="emailUsed && item.type === 'email'"
                      class="error"
                    >This email has already registered</span>
                    <span
                      v-else-if="item.error"
                      class="error"
                    >{{ item.error }}</span>

                    <label for="name">{{ item.label }}</label>
                    <input
                      v-model="item.value"
                      :type="item.type"
                      name="name"
                      class="form-control required"
                      @blur="validation(item)"
                    >
                  </div>
                </div>
                <div
                  v-show="pageStep == 'provider_status'"
                  class="step"
                >
                  <h2 class="section_title">
                    Service Provider Status
                  </h2>
                  <h3 class="main_question">
                    Tell us about your service provider status:
                  </h3>
                  <div class="form-group add_bottom_30 add_top_20">
                    <span
                      v-if="!clec_file && transition"
                      class="error"
                    >Required</span>

                    <label>Upload CLEC Certificate:<br><small>(File accepted: jpeg, png, jpg,
                      pdf)</small></label>
                    <div class="fileupload">
                      <input
                        id="fileupload2"
                        type="file"
                        name="fileuploa2"
                        accept=".jpeg, .png, .jpg, .pdf"
                        class="required"
                        @change="clec_file = $event.srcElement.files[0]"
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label
                      v-for="item in step3_items"
                      :key="item.id"
                      class="container_check version_2"
                    >{{ item.text }}
                      <span
                        v-if="
                          status_company.length < 3 &&
                            transition &&
                            !status_company.includes(item.value)
                        "
                        class="error"
                      >Required</span>
                      <input
                        :id="item.id"
                        v-model="status_company"
                        type="checkbox"
                        :value="item.value"
                        class="required"
                      >
                      <span class="checkmark" />
                    </label>
                  </div>
                  <div class="form-group form-ocn input">
                    <span
                      v-if="!ocn[0].value && transition"
                      class="error"
                    >Required</span>
                    <label
                      class="ocn"
                      for="name"
                    >Enter your OCN
                      <div class="action">
                        <button
                          type="button"
                          class="btn btn-danger"
                          :disabled="ocn.length == 1"
                          @click="removeOCN"
                        >
                          -
                        </button>

                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="addOCN"
                        >+
                        </button>
                      </div>
                    </label>
                    <div
                      v-for="(item, index) in ocn"
                      :key="index"
                      class="ocn-group"
                    >
                      <span
                        v-if="item.error"
                        :key="item.error"
                        class="error"
                      >{{
                        item.error
                      }}</span>
                      <input
                        :key="index"
                        v-model="item.value"
                        type="text"
                        name="name"
                        class="form-control required"
                        @blur="validOCN(item)"
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-show="pageStep == 'id_verification'"
                  class="step"
                >
                  <h2 class="section_title">
                    Begin your ID-Verification
                  </h2>
                  <span
                    v-if="transition && !available"
                    class="error"
                  >Required</span>
                  <!--                  <h3 class="main_question">-->
                  <!--                    Verify your identity to participate in tokensale.-->
                  <!--                  </h3>-->

                  <div class="form-step form-step2">
                    <div class="form-step-fields card-innr">
                      <div class="note note-plane note-light-alt note-md pdb-0-5x">
                        <p class="text-center">
                          You are required to provide a form of ID in order by subscribe to
                          Peeringhub's Certificate Service or Signing Service.
                        </p>
                      </div>
                      <div class="gaps-2x" />
                      <ul
                        class="nav nav-tabs nav-tabs-bordered row flex-wrap guttar-20px"
                        role="tablist"
                      >
                        <li
                          class="nav-item flex-grow-0"
                          @click=";(id_type = 'PASSPORT'), clearIDFile()"
                        >
                          <a
                            class="nav-link d-flex align-items-center active"
                            data-toggle="tab"
                            href="#passport"
                          >
                            <div class="nav-tabs-icon">
                              <img
                                src="/img/icon-passport.png"
                                alt="icon"
                              ><img
                                src="/img/icon-passport-color.png"
                                alt="icon"
                              >
                            </div>
                            <span>Passport</span></a>
                        </li>
                        <li
                          class="nav-item flex-grow-0"
                          @click=";(id_type = 'NATIONAL_CARD'), clearIDFile()"
                        >
                          <a
                            class="nav-link d-flex align-items-center"
                            data-toggle="tab"
                            href="#national-card"
                          >
                            <div class="nav-tabs-icon">
                              <img
                                src="/img/icon-national-id.png"
                                alt="icon"
                              ><img
                                src="/img/icon-national-id-color.png"
                                alt="icon"
                              >
                            </div>
                            <span>National Card</span></a>
                        </li>
                        <li
                          class="nav-item flex-grow-0"
                          @click=";(id_type = 'DRIVER_LICENSE'), clearIDFile()"
                        >
                          <a
                            class="nav-link d-flex align-items-center"
                            data-toggle="tab"
                            href="#driver-licence"
                          >
                            <div class="nav-tabs-icon">
                              <img
                                src="/img/icon-licence.png"
                                alt="icon"
                              ><img
                                src="/img/icon-licence-color.png"
                                alt="icon"
                              >
                            </div>
                            <span>Driver’s License</span></a>
                        </li>
                      </ul>
                      <!-- .nav-tabs-line -->
                      <div
                        id="myTabContent"
                        class="tab-content"
                      >
                        <div
                          id="passport"
                          class="tab-pane fade show active"
                        >
                          <h5 class="text-secondary font-bold">
                            To avoid delays when verifying account, please make sure bellow:
                          </h5>
                          <ul class="list-check pl-4 list-disc">
                            <li>Chosen credential must not be expired.</li>
                            <li>
                              Document should be in good condition and clearly visible.
                            </li>
                            <li>Make sure that there is no light glare on the card.</li>
                          </ul>
                          <div class="gaps-2x" />
                          <div class="row align-items-center relative">
                            <span
                              v-if="transition && !available"
                              class="error left"
                            >Required</span>
                            <div class="col-sm-8">
                              <h5 class="text-center">
                                Upload Here Your ID
                              </h5>
                              <div class="upload-box">
                                <div class="upload-zone dropzone dz-clickable">
                                  <div class="dz-message">
                                    <input
                                      id="fileupload3"
                                      type="file"
                                      name="fileupload3"
                                      accept=".jpeg, .png, .jpg, .pdf"
                                      class="required"
                                      @change="id_file = $event.srcElement.files[0]"
                                    >
                                    <!-- <span class="dz-message-text"
                                                                          >Drag and drop file</span
                                                                        ><span class="dz-message-or">or</span
                                                                        ><button type="button" class="btn btn-primary">
                                                                          SELECT
                                                                        </button> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4 d-none d-sm-block">
                              <div class="mx-md-4">
                                <img
                                  src="/img/vector-licence.png"
                                  alt="vector"
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- .tab-pane -->
                        <div
                          id="national-card"
                          class="tab-pane fade"
                        >
                          <h5 class="text-secondary font-bold">
                            To avoid delays when verifying account, please make sure bellow:
                          </h5>
                          <ul class="list-check">
                            <li>-Chosen credential must not be expired.</li>
                            <li>
                              -Document should be in good condition and clearly visible.
                            </li>
                            <li>-Make sure that there is no light glare on the card.</li>
                          </ul>
                          <div class="gaps-2x" />
                          <div class="row align-items-center relative">
                            <span
                              v-if="transition && !available"
                              class="error left"
                            >Required</span>
                            <div class="col-sm-8">
                              <h5 class="text-center">
                                Upload Here Your National id Front Side
                              </h5>
                              <div class="upload-box">
                                <div class="upload-zone dropzone dz-clickable">
                                  <div class="dz-message">
                                    <input
                                      id="fileupload4"
                                      type="file"
                                      name="fileupload4"
                                      accept=".jpeg, .png, .jpg, .pdf"
                                      class="required"
                                      @change="id_file = $event.srcElement.files[0]"
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4 d-none d-sm-block">
                              <div class="mx-md-4">
                                <img
                                  src="/img/vector-licence.png"
                                  alt="vector"
                                >
                              </div>
                            </div>
                          </div>
                          <div class="gaps-3x" />
                          <div class="row align-items-center relative">
                            <span
                              v-if="transition && !available && !id_file_backside"
                              class="error left"
                            >Required</span>
                            <div class="col-sm-8">
                              <h5 class="text-center">
                                Upload Here Your National id Back Side Now
                              </h5>
                              <div class="upload-box">
                                <div class="upload-zone dropzone dz-clickable">
                                  <div class="dz-message">
                                    <input
                                      id="fileupload5"
                                      type="file"
                                      name="fileupload5"
                                      accept=".jpeg, .png, .jpg, .pdf"
                                      class="required"
                                      @change="id_file_backside = $event.srcElement.files[0]"
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4 d-none d-sm-block">
                              <div class="mx-md-4">
                                <img
                                  src="/img/vector-licence.png"
                                  alt="vector"
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- .tab-pane -->
                        <div
                          id="driver-licence"
                          class="tab-pane fade"
                        >
                          <h5 class="text-secondary font-bold">
                            To avoid delays when verifying account, please make sure bellow:
                          </h5>
                          <ul class="list-check">
                            <li>-Chosen credential must not be expired.</li>
                            <li>
                              -Document should be in good condition and clearly visible.
                            </li>
                            <li>-Make sure that there is no light glare on the card.</li>
                          </ul>
                          <div class="gaps-2x" />
                          <div class="row align-items-center relative">
                            <span
                              v-if="transition && !available"
                              class="error left"
                            >Required</span>
                            <div class="col-sm-8">
                              <h5 class="text-center">
                                Upload Here Your Driving Licence Copy Now
                              </h5>
                              <div class="upload-box">
                                <div class="upload-zone dropzone dz-clickable">
                                  <div class="dz-message">
                                    <input
                                      id="fileupload6"
                                      type="file"
                                      name="fileupload6"
                                      accept=".jpeg, .png, .jpg, .pdf"
                                      class="required"
                                      @change="id_file = $event.srcElement.files[0]"
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4 d-none d-sm-block">
                              <div class="mx-md-4">
                                <img
                                  src="/img/vector-licence.png"
                                  alt="vector"
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- .tab-pane -->
                      </div>
                      <!-- .tab-content -->
                    </div>
                    <!-- .step-fields -->
                  </div>
                </div>
                <div
                  v-if="pageStep == 'step4'"
                  class="step"
                  data-state="branchtype"
                >
                  <h2 class="section_title">
                    What Services Do you Need?
                  </h2>
                  <div class="form-group">
                    <span
                      v-if="transition && !available"
                      class="error"
                    >Required</span>
                    <label
                      v-for="item in step4_items"
                      :key="item.id"
                      class="container_radio version_2"
                    >{{ item.text }}
                      <input
                        v-model="step4"
                        type="checkbox"
                        name="availability"
                        class="required"
                        :value="item.text"
                      >
                      <span class="checkmark" />
                    </label>
                  </div>
                </div>
                <div
                  v-if="pageStep == '499a'"
                  class="step"
                >
                  <h2 class="section_title">
                    What is Your 499A Filer ID?
                  </h2>
                  <div
                    v-for="item in a_items"
                    :key="item.label"
                    class="form-group input"
                  >
                    <span
                      v-if="item.error"
                      class="error"
                    >{{ item.error }}</span>
                    <label for="name">{{ item.label }}</label>
                    <input
                      v-model="item.value"
                      :type="item.type"
                      name="name"
                      class="form-control required"
                      @blur="validation(item)"
                    >
                  </div>
                </div>
                <div
                  v-if="
                    pageStep == 'signing_service'
                  //status_company == 'Signing'
                  "
                  class="step"
                  data-state="branchtype"
                >
                  <h2 class="section_title">
                    What is your service volume?
                  </h2>
                  <div
                    v-for="item in notServiceProvider"
                    :key="item.text"
                    class="form-group input"
                  >
                    <span
                      v-if="item.error"
                      class="error"
                    >Required</span>
                    <label for="name">{{ item.text }}</label>
                    <input
                      v-model="item.value"
                      type="number"
                      name="name"
                      :min="item.min ? item.min : null"
                      class="required form-control"
                      @blur="validation(item)"
                    >
                  </div>
                </div>
                <div
                  v-if="pageStep == 'signing_service_2'"
                  class="step"
                >
                  <h2 class="section_title">
                    What is Your Source of Number?
                  </h2>
                  <div class="form-group">
                    <span
                      v-if="transition && !tn_source"
                      class="error"
                    >Required</span>
                    <label
                      v-for="item in tn_sources"
                      :key="item.id"
                      class="container_radio version_2"
                    >{{ item.text }}
                      <input
                        v-model="tn_source"
                        type="radio"
                        name="availability"
                        class="required"
                        :value="item.value"
                      >
                      <span class="checkmark" />
                    </label>
                  </div>
                </div>
                <div
                  v-show="pageStep == 'verify_certificate'"
                  class="step"
                >
                  <h2 class="section_title">
                    Verify Your Company
                  </h2>
                  <div>
                    <h5 class="text-secondary font-bold">
                      To avoid delays when verifying account, please make sure bellow:
                    </h5>
                    <ul class="list-check">
                      <!-- <li>-Chosen credential must not be expired.</li> -->
                      <li>-Document should be in good condition and clearly visible.</li>
                      <!-- <li>-Make sure that there is no light glare on the card.</li> -->
                    </ul>
                  </div>
                  <div class="gaps-2x" />

                  <div class="row align-items-center relative">
                    <span
                      v-show="transition && !company_file"
                      class="error left"
                    >Required</span>
                    <div class="col-sm-8">
                      <h5 class="text-center">
                        Upload Here Your Company Certificate
                      </h5>
                      <div class="upload-box">
                        <div class="upload-zone dropzone dz-clickable">
                          <div class="dz-message">
                            <input
                              id="fileupload7"
                              type="file"
                              name="fileupload7"
                              accept=".jpeg, .png, .jpg, .pdf"
                              class="required"
                              @change="company_file = $event.srcElement.files[0]"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 d-none d-sm-block">
                      <div class="mx-md-4">
                        <img
                          src="/img/vector-licence.png"
                          alt="vector"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="pageStep == 'verify_address'"
                  class="step"
                >
                  <h2 class="section_title">
                    Verify Your Address
                  </h2>

                  <div>
                    <h5 class="text-secondary font-bold">
                      To avoid delays when verifying account, please make sure bellow:
                    </h5>
                    <ul class="list-check">
                      <li>-Eligible documents are Utility Bills and Bank Statement.</li>
                      <!-- <li>-Document should be in good condition and clearly visible.</li>
                                            <li>-Make sure that there is no light glare on the card.</li> -->
                    </ul>
                  </div>
                  <div class="gaps-2x" />
                  <div class="row align-items-center relative">
                    <span
                      v-show="transition && !address_file"
                      class="error left"
                    >Required</span>
                    <div class="col-sm-8">
                      <h5 class="text-center">
                        Upload Here Your Proof-of-Address Document
                      </h5>
                      <div class="upload-box">
                        <div class="upload-zone dropzone dz-clickable">
                          <div class="dz-message">
                            <input
                              id="fileupload1"
                              type="file"
                              name="fileupload1"
                              accept=".jpeg, .png, .jpg, .pdf"
                              class="required"
                              @change="handleAddFile"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 d-none d-sm-block">
                      <div class="mx-md-4">
                        <img
                          src="/img/vector-licence.png"
                          alt="vector"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="pageStep == 'password'"
                  class="step"
                >
                  <h2 class="section_title">
                    Enter Your Password
                  </h2>
                  <div class="form-group input">
                    <span
                      v-if="pass.password.error"
                      class="error"
                    >{{ pass.password.error }}</span>
                    <label for="name">Password</label>
                    <input
                      v-model="pass.password.value"
                      type="password"
                      name="name"
                      class="form-control required"
                      @blur="validation(pass.password), passwordMatch()"
                    >
                  </div>
                  <div class="form-group input mt-3">
                    <span
                      v-if="pass.confirm.error"
                      class="error"
                    >{{ pass.confirm.error }}</span>
                    <label for="name">Confirm Password</label>
                    <input
                      v-model="pass.confirm.value"
                      type="password"
                      name="name"
                      class="form-control required"
                      @blur="validation(pass.confirm), passwordMatch()"
                    >
                  </div>
                </div>
                <div
                  v-if="pageStep == 'submit'"
                  id="end"
                  class="submit step"
                >
                  <div class="summary">
                    <div class="wrapper">
                      <h3>
                        Thank you for your time <br>
                        {{ step1_items[0].value }}!
                      </h3>
                      <p>
                        We will contact you shortly at the following email address
                        <strong id="email_field">{{ step1_items[8].value }}</strong>
                      </p>
                    </div>
                    <div class="text-center">
                      <div class="form-group terms">
                        <label
                          class="container_check"
                        >I Have Read
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#terms-txt"
                          >Terms & Conditions</a>
                          And
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#terms-txt"
                          >Privacy Policy</a>
                          <input
                            v-model="accept[0]"
                            type="checkbox"
                            name="terms"
                            value="Yes"
                            class="required"
                          >
                          <span class="checkmark" />
                        </label>
                        <label
                          class="container_check"
                        >All The Personal Information I Have Entered Is Correct
                          <input
                            v-model="accept[1]"
                            type="checkbox"
                            name="terms"
                            value="Yes"
                            class="required"
                          >
                          <span class="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- /step last-->
              </div>
              <!-- /middle-wizard -->
              <div id="bottom-wizard">
                <div
                  class="text-left pt-2 mb-auto"
                  style="width: calc(100% - 200px)"
                >
                  <div v-if="emailUsed">
                    You may go to
                    <router-link to="/auth/login">
                      Login
                    </router-link>
                    or
                    <router-link to="/auth/forgot-password">
                      Forgot Password
                    </router-link>
                    to
                    retrieve your password
                  </div>
                  <div
                    v-else
                    class="mr-2"
                  >
                    Already have an account?
                    <router-link
                      to="/auth/login"
                      class="vb__utils__link"
                    >
                      Sign in
                    </router-link>
                  </div>
                </div>
                <div style="margin-left: auto">
                  <button
                    type="button"
                    name="backward"
                    class="backward"
                    :disabled="currentStep == 0"
                    @click="prevStep"
                  >
                    Prev
                  </button>
                  <button
                    v-if="currentStep !== steps"
                    type="button"
                    name="forward"
                    class="forward"
                    :class="disStep ? 'disabledBtn' : ''"
                    @click="nextStep"
                  >
                    Next
                  </button>
                  <button
                    v-if="currentStep == steps"
                    type="button"
                    name="process"
                    class="submit"
                    :disabled="!accept[0] || !accept[1] || loading"
                    @click="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <!-- /bottom-wizard -->
            </form>
          </div>
          <!-- /Wizard container -->
        </div>
        <!-- /content-right-->
      </div>
      <!-- /row-->
    </div>
    <!-- /container-fluid -->

    <div class="cd-overlay-nav">
      <span />
    </div>
    <!-- /cd-overlay-nav -->

    <div class="cd-overlay-content">
      <span />
    </div>
    <!-- /cd-overlay-content -->

    <!-- <a href="#0" class="cd-nav-trigger">Menu<span class="cd-icon"></span></a> -->
    <!-- /menu button -->

    <!-- Modal terms -->

    <!-- /.modal-dialog -->
  </div>

  <!-- /Preload -->

  <!-- /.modal -->
</template>

<script>
    import Swal from 'sweetalert2/dist/sweetalert2.js'
    import axios from '@/axios.js'
    import {useRoute, useRouter} from 'vue-router'

    export default {
        data() {
            return {
                emailUsed: false,
                loading: false,
                currentStep: 0,
                router: useRouter(),
                status_items: [
                    {id: 1, text: 'Yes', value: 'EXISTING_SERVICE_PROVIDER'},
                    {id: 2, text: 'I am applying', value: 'APPLYING_SERVICE_PROVIDER'},
                    {id: 3, text: 'No', value: 'NOT_A_SERVICE_PROVIDER'},
                ],
                pass: {
                    password: {
                        value: null,
                        error: null,
                        type: 'password',
                    },
                    confirm: {
                        value: null,
                        error: null,
                        type: 'confirm_password',
                    },
                },
                step1_items: [
                    {
                        type: "text",
                        key: "first_name",
                        label: "First Name",
                        value: null,
                        error: null,
                    },
                    {
                        type: "text",
                        key: "last_name",
                        label: "Last Name",
                        value: null,
                        error: null,
                    },
                    // {
                    //   type: "password",
                    //   key: "password",
                    //   label: "Password",
                    //   value: null,
                    //   error: null,
                    // },
                    {
                        type: "text",
                        key: "address",
                        label: "Address",
                        value: null,
                        error: null,
                    },
                    {
                        type: "text",
                        key: "company_name",
                        label: "Company Name",
                        value: null,
                        error: null,
                    },
                    {
                        type: "text",
                        key: "city",
                        label: "City",
                        value: null,
                        error: null,
                    },
                    {
                        type: "text",
                        key: "state",
                        label: "State",
                        value: null,
                        error: null,
                    },
                    {
                        type: "text",
                        key: "primary_contact",
                        label: "Primary Contact",
                        value: null,
                        error: null,
                    },
                    {
                        type: "number",
                        key: "phone",
                        label: "Phone",
                        value: null,
                        error: null,
                    },
                    {
                        type: "email",
                        key: "email",
                        label: "Email",
                        value: null,
                        error: null,
                    },
                ],
                step3_items: [
                    {
                        id: 1,
                        text:
                            '2020 FCC 499-A Form - The 499-A annual reporting worksheet is used to determine required contributions to the Universal Service Fund. Carriers must have an up-to-date 499-A from on file with the FCC',
                        value: 'has_499a',
                    },
                    {
                        id: 2,
                        text:
                            'Valid OCN - An Operating Company Number (OCN) is the 4-character identification assigned to every North American phone company by the National Exchange Carrier Association (NECA). An OCN is required to obtain a TOKEN in the STIR/SHAKEN ecosystem.',
                        value: 'has_ocn',
                    },
                    {
                        id: 3,
                        text:
                            'Access to U.S. Telephone Numbers - Carriers must have direct access to telephone numbers from the North American Numbering Plan Administrator (NANPA) and/or the National Pooling Administrator.',
                        value: 'has_tn_access',
                    },
                ],
                tn_sources: [
                    {id: 1, text: 'I own my number resources', value: 'HAS_OWN_NUMBERS'},
                    {
                        id: 2,
                        text: 'I buy numbers from other companies',
                        value: 'BUY_OTHER_PEOPLE_NUMBERS',
                    },
                    {
                        id: 3,
                        text: 'I want to use Peeringhub Numbers',
                        value: 'BUY_PEERINGHUB_NUMBERS',
                    },
                ],
                notServiceProvider: [
                    {
                        text: 'Monthly TDM Origination(Outbound Call Volume)',
                        value: null,
                        key: 'monthly_tdm_orig_min',
                        error: '',
                    },
                    {
                        text: 'Monthly TDM Termination(Outbound Call Volume)',
                        value: null,
                        key: 'monthly_tdm_term_min',
                        error: '',
                    },
                    {
                        text: 'Monthly SIP Origination(Outbound Call Volume)',
                        value: 500000,
                        min: 500000,
                        key: 'monthly_sip_orig_min',
                        error: '',
                    },
                    {
                        text: 'Monthly SIP Termination(Outbound Call Volume)',
                        value: 250000,
                        min: 250000,
                        key: 'monthly_sip_term_min',
                        error: '',
                    },
                ],
                tn_source: null,
                status: null,
                status_company: [],
                step4: [],
                step: 6,
                ocn: [
                    {
                        value: '',
                        error: '',
                    },
                ],
                clec_file: null,
                transition: false,
                accept: [false, false],
                id_type: 'PASSPORT',
                id_file: null,
                id_file_backside: null,
                address_file: null,
                company_file: null,
                reg_uuid: null,
                a_items: [
                    {
                        label: 'Enter Your 499A Filer ID',
                        error: '',
                        value: null,
                    },
                    {
                        label: 'Legal Entity that Files the 499 Worksheet',
                        error: '',
                        value: null,
                    },
                ],
                disStep: false
            }
        },
        computed: {
            steps() {
                return this.pageSteps.length - 1
            },
            pageSteps() {
                if (this.step4.indexOf('Certificate Service') > -1) {
                    return [
                        'first',
                        'about_company',
                        'step4',
                        'provider_status',
                        'verify_certificate',
                        'verify_address',
                        'id_verification',
                        'password',
                        'submit',
                    ]
                } else if (this.step4.indexOf('Signing Service') > -1) {
                    return [
                        'first',
                        'about_company',
                        'step4',
                        'signing_service',
                        'signing_service_2',
                        'verify_certificate',
                        'verify_address',
                        '499a',
                        'id_verification',
                        'password',
                        'submit',
                    ]
                } else {
                    return ['first', 'about_company', 'step4', 'password', 'submit']
                }
            },
            pageStep() {
                return this.pageSteps[this.currentStep]
            },
            progressBarWidth() {
                const stepWidth = 100 / this.steps
                return this.currentStep * stepWidth
            },
            step4_items() {
                let arr = []
                switch (this.status) {
                    case 'EXISTING_SERVICE_PROVIDER': {
                        arr = [
                            {id: 1, text: 'Certificate Service'},
                            {id: 2, text: 'Authentication Service'},
                            {id: 3, text: 'Verification Service'},
                        ]
                        break
                    }
                    case 'APPLYING_SERVICE_PROVIDER': {
                        arr = [
                            {id: 1, text: 'Certificate Service'},
                            {id: 3, text: 'Verification Service'},
                        ]
                        break
                    }
                    case 'NOT_A_SERVICE_PROVIDER': {
                        arr = [
                            {id: 3, text: 'Verification Service'},
                            {id: 4, text: 'Signing Service'},
                        ]
                        break
                    }
                }
                return arr
            },
            available() {
                let value = true
                switch (this.pageStep) {
                    case 'first': {
                        if (!this.status) value = false
                        break
                    }
                    case 'about_company': {
                        let step1_value = true
                        this.step1_items.forEach((el) => {
                            if (el.error || !el.value) step1_value = false
                        })
                        value = step1_value
                        break
                    }
                    case 'provider_status': {
                        if (this.status_company.length !== 3 || !this.clec_file) {
                            value = false
                        } else {
                            this.ocn.forEach((el) => {
                                if (el.error) value = false
                            })
                        }
                        break
                    }
                    case 'signing_service': {
                        this.notServiceProvider.forEach((el) => {
                            if (el.error || !el.value) value = false
                        })
                        break
                    }
                    case 'signing_service_2': {
                        if (!this.tn_source) value = false
                        break
                    }
                    case 'id_verification': {
                        if (!this.id_file) value = false
                        else if (this.id_file && this.id_type == 'NATIONAL_CARD' && !this.id_file_backside)
                            value = false
                        break
                    }
                    case 'verify_address': {
                        if (!this.address_file) value = false
                        break
                    }
                    case 'verify_certificate': {
                        if (!this.company_file) value = false
                        break
                    }
                    case '499a': {
                        this.a_items.forEach((el) => {
                            if (el.error || !el.value) value = false
                        })
                        break
                    }
                    case 'step4': {
                        if (!this.step4) value = false
                        break
                    }
                    case 'password': {
                        if (
                            this.pass.password.value.length < 7 ||
                            this.pass.password.value !== this.pass.confirm.value
                        ) {
                            value = false
                            this.validation(this.pass.password)
                            this.validation(this.pass.confirm)
                        }
                    }
                }
                return value
            },
        },
        watch: {
            pageStep() {
                this.disableNextBtn5()
            }
        },
        methods: {
            handleAddFile(event) {
                this.address_file = event.srcElement.files[0];
                this.disableNextBtn5();
            },
            prevStep() {
                this.currentStep--
            },
            disableNextBtn5() {
                if (this.pageStep === 'verify_address' && !this.address_file) {
                    this.disStep = true
                } else {
                    this.disStep = false
                }
            },
            nextStep() {
                this.transition = true
                switch (this.pageStep) {
                    case 'about_company': {
                        this.step1_items.forEach((el) => this.validation(el))
                        break
                    }
                    case 'signing_service': {

                      this.notServiceProvider.forEach((el) => {
                            this.validation(el)
                        })
                        break
                    }
                    case '499a': {
                        this.a_items.forEach((el) => {
                            this.validation(el)
                        })
                        break
                    }
                }
                if (this.available) {
                    this.currentStep += 1

                    this.transition = false
                }
            },
            validOCN(item) {
                if (item.value.length !== 4) {
                    item.error = 'length should be 4'
                } else if (item.error) {
                    item.error = ''
                }
            },
            validation(item) {
                if (this.emailUsed) this.emailUsed = false
                if (!item.value) {
                    item.error = 'Required'
                } else if (item.type == 'email' && !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(item.value)) {
                    item.error = 'Enter correct mail'
                } else if (item.key === 'phone' && item.value.length < 7) {
                    item.error = 'Enter more numbers'
                } else if (item.type === 'confirm_password' && this.pass.password.value !== item.value) {
                    item.error = 'Password does not match'
                } else if (item.type === 'password' && item.value.length < 7) {
                    item.error = 'Password must be longer than 6 characters'
                } else if (item.error) item.error = null
            },
            passwordMatch() {
                if (this.pass.confirm.value > 0) {
                    this.validation(this.pass.confirm)
                }
            },
            addOCN() {
                this.ocn.push({
                    value: '',
                    error: '',
                })
            },
            clearIDFile() {
                this.id_file = null
                this.id_file_backside = null
                this.transition = null
            },
            removeOCN() {
                this.ocn.splice(this.ocn.length - 1, 1)
            },
            async uploadDocument(reg_uuid, type) {
                let formData = new FormData()
                if (type == 'CLEC') {
                    formData.append('file', this.clec_file)
                    this.uploadOneFile(reg_uuid, 'clec_certificate', formData)
                } else if (type == 'address') {
                    formData.append('file', this.address_file)
                    this.uploadOneFile(reg_uuid, 'proof_of_address', formData)
                } else if (type == 'company') {
                    formData.append('file', this.company_file)
                    this.uploadOneFile(reg_uuid, 'corporation_certificate', formData)
                } else {
                    formData.append('file', this.id_file)

                    if (!type && this.id_type !== 'NATIONAL_CARD') {
                        const type = this.id_type == 'DRIVER_LICENSE' ? 'license' : 'id'
                        this.uploadOneFile(reg_uuid, type, formData)
                    } else {
                        await this.uploadOneFile(reg_uuid, 'national_card_front', formData)
                        if (this.id_file_backside) {
                            formData.file = this.id_file_backside
                        }
                        await this.uploadOneFile(reg_uuid, 'national_card_back', formData)
                    }
                }
            },
            async uploadOneFile(reg_uuid, type, formData) {
                await axios
                    .post(`/registration/${reg_uuid}/upload/${type}`, formData)
                    .catch((err) => console.log(err))
            },
            submit() {
                this.loading = true
                let item = {
                    status: 'EXISTING_SERVICE_PROVIDER',
                    company_name: 'string',
                    primary_contact: 'string',
                    email: 'string',
                    phone: 'string',
                    has_499a: false,
                    has_ocn: false,
                    has_tn_access: false,
                    us499a: '',
                    // license_file_name: "string",
                    // id_file_name: "string",
                    id_type: 'PASSPORT',
                    req_ca: false,
                    req_as: false,
                    req_vs: false,
                    req_ss: false,
                    company_address1: 'string',
                    company_address2: '',
                    monthly_tdm_orig_min: 0,
                    monthly_tdm_term_min: 0,
                    monthly_sip_orig_min: 0,
                    monthly_sip_term_min: 0,
                    tn_source: 'NONE',
                    password: this.pass.password.value,
                    city: '',
                    first_name: '',
                    last_name: '',
                    state: '',
                    address: ''
                }

                if (this.ocn.length > 0) {
                    item.has_ocn = true
                    let arr = []
                    this.ocn.forEach((el) => {
                        arr.push(el.value)
                    })
                    item.ocn = arr.join(', ')
                    item.has_tn_access = true
                }
                if (this.a_items[0].value) {
                    item.has_499a = true
                    item.us499a = this.a_items[0].value
                }

                this.step1_items.forEach((el) => {
                    item[el.key] = el.value
                })

                if (this.notServiceProvider.monthly_tdm_orig_min) {
                    this.notServiceProvider.forEach((el) => {
                        item[el.key] = el.value
                    })
                }
                if (this.tn_source) {
                    item.tn_source = this.tn_source
                }
                item.status = this.status
                if (this.id_type) {
                    item.id_type = this.id_type
                }
                this.step4.forEach((el) => {
                    switch (el) {
                        case 'Authentication Service': {
                            item.req_as = true
                            break
                        }
                        case 'Certificate Service': {
                            item.req_ca = true
                            break
                        }
                        case 'Verification Service': {
                            item.req_vs = true
                            break
                        }
                        case 'Signing Service': {
                            item.req_ss = true
                            break
                        }
                    }
                })

                axios
                    .post(`/register`, item)
                    .then(async (res) => {
                        const available =
                            this.status_company.includes('Certificate Service') ||
                            this.status_company.includes('Signing Service')
                        const availableCLEC = this.status_company.includes('Certificate Service')

                        if (this.id_file && available) {
                            await this.uploadDocument(res.data.id)
                        }
                        if (this.clec_file && availableCLEC) {
                            await this.uploadDocument(res.data.id, 'CLEC')
                        }
                        if (this.company_file && available) {
                            await this.uploadDocument(res.data.id, 'company')
                        }
                        if (this.address_file && available) {
                            await this.uploadDocument(res.data.id, 'address')
                        }
                        Swal.fire({
                            title: 'Success!',
                            text: 'Registration successfully completed',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            if (result.value) {
                                this.router.push('/')
                            }
                        })
                        this.loading = false
                    })
                    .catch((err) => {
                        const error = err.response.data.message
                        let messages
                        if (Array.isArray(error)) messages = [...err.response.data.message]
                        else messages = err.response.data.message
                        Swal.fire({
                            title: 'Error!',
                            text: messages,
                            icon: 'error',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            if (result.value) {
                                if (messages.includes('Email already')) {
                                    this.emailUsed = true
                                    this.currentStep = 1
                                }
                            }
                        })
                        this.loading = false
                    })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .disabledBtn {
        opacity: 0.7;
    }

    * {
        font-family: "Roboto", sans-serif;
    }

    a {
        color: #007bff;
    }

    h2,
    h3 {
        text-align: center;
    }

    .ant-layout-content.vb__layout__content {
        padding: 0;
    }

    .error {
        &.left {
            width: 70px;
            left: 23px;
            top: -3px;
        }
    }

    #wizard_container {
        margin-top: auto;
        margin-bottom: auto;
        height: 100%;
    }

    .backward {
        margin-right: 10px;
    }

    .dz-message {
        margin: 0 auto;
        width: 90%;
    }

    input[type='file'] {
        background: none;
    }

    .form-ocn {
        .form-control {
            margin-bottom: 0.5rem;
        }

        .ocn-group {
            position: relative;

            span.error {
                top: -20px;
            }
        }

        .ocn {
            display: flex;
            align-items: flex-start;

            .action {
                display: flex;
                margin-left: auto;

                .btn {
                    min-width: 10px;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 12px;

                    &.btn-danger {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .nav-tabs-bordered {
        flex-wrap: nowrap !important;
    }

    .form-group.terms .container_check {
        margin-bottom: 0.75rem;
    }

    h5 {
        font-weight: 400;
        font-size: 14px;
    }

    h2,
    h5,
    h3 {
        color: rgba(65, 131, 215, 1) !important;
    }

    h3.main_question,
    .text-secondary {
        font-size: 16px;
    }

    .nav-tabs-bordered .nav-link.active {
        border: 2px solid rgba(65, 131, 215, 1);
        color: rgba(65, 131, 215, 1);
    }

    h3.main_question {
        font-weight: 600;
    }

    .text-secondary {
        margin-top: 10px;
        margin-bottom: 0;
        font-weight: 500;
    }

    .text-secondary,
    .font-left {
        text-align: left;
    }

    #bottom-wizard {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #top-wizard {
        height: 53px;
    }

    #wrapped {
        position: relative;
        min-height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .nav-tabs-bordered .nav-item {
        // margin-bottom: 0;
    }

    .nav-tabs {
        border-bottom: none;
    }

    #middle-wizard {
        margin-top: auto;
        margin-bottom: auto;
    }

    #bottom-wizard {
        margin-top: 0;
    }

    .list-disc li{
        list-style: disc !important;
    }
</style>